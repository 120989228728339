<template>
  <v-card elevation="0">
    <div class="d-flex align-end">
      <div>
        <div class="wallet-amount text-subtitle-1">
          {{ $t("components.walletAmount") }} /
          {{ $priceFormatter(toART(web3.myART)) }} {{ $t("global.art") }}
        </div>
        <div
          :class="{ 'red--text': !isAdmin }"
          class="wallet text-h5 font-weight-regular"
        >
          {{ $shortAddress(web3.account, windowWidth) }}
        </div>
      </div>
      <v-btn
        v-if="windowWidth > 448"
        target="_blank"
        class="ml-5"
        color="#666666"
        dark
        :href="etherscanUrl(web3.account, 'address20')"
        >{{web3.network == 0? 'ETHERSCAN': 'POLYGONSCAN'}}</v-btn
      >
    </div>
    <v-alert v-if="!isAdmin" class="mt-5" type="error"
      >You are not admin</v-alert
    >
  </v-card>
</template>
<script>
const BigNumber = require("bignumber.js");
import windowWidth from "@/mixins/windowWidth";
export default {
  mixins: [windowWidth],

  data() {
    return {};
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
    isAdmin() {
      return (
        [
          "0x444f875c1754eb221032f8023dc03de2bc2098ff",
          "0x32A59Da56648C7ab8192345707E8F0737A9bbE10",
          "0x6a0479c5313a87a656a76B6BCDC211967C298e53",
          "0xdD5775D8F839bDEEc91a0f7E47f3423752Ed6e4F",
          "0xF6F3ba1E29798226699D93c9924a33683C3c2570"
        ]
          .map((a) => a.toLowerCase())
          .indexOf(this.web3.account.toLowerCase()) != -1
      );
    },
  },

  methods: {
    etherscanUrl: function (hash, type, network) {
      if(typeof this.$etherscanUrl != "undefined")
        return this.$etherscanUrl(hash, type, network);
      else return "";
    },
    toART: function (val) {
      return new BigNumber(val).dividedBy(10 ** 18).toString(10);
    },
  },
};
</script>
<style lang="scss" scoped></style>
