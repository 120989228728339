<template>
  <div class="transfer">
    <div class="wrap pb-16">
      <page-info
        :name="$t('global.transfer')"
        position="left"
        :description="$t('pages.sendToMultipleWallets')"
      />
      <wallet />
      <switcher
        :buttons="['toSend', 'sent']"
        position="center"
        @selected="(e) => (selectedComponent = e)"
      />
      <v-card class="mb-4 d-flex" elevation="0">
        <v-btn
          v-if="selectedComponent == 'toSend'"
          :disabled="selected.length == 0"
          :loading="waitingForBlockchain"
          @click="transfer"
          class="ml-auto"
          color="#D4AF37DE"
          :dark="selected.length > 0"
          >{{ $t("buttons.transfer") }}</v-btn
        >
        <v-btn color="#666666" class="ml-2" :disabled="selected.length == 0"
          :dark="selected.length > 0" @click="hideSelected">Hide</v-btn>
      </v-card>
      <v-card
        class="d-flex"
        :class="{
          'flex-column': windowWidth < 767,
          'justify-space-between align-center': windowWidth > 767,
        }"
        elevation="0"
      >
        <div class="combobox-wrapper">
          <v-text-field
            v-model="search"
            :loading="loading"
            :rules="[minChars]"
            :label="$t('labels.searchWallet')"
            outlined
            clearable
          />
        </div>
        <div v-if="selectedComponent == 'toSend'" class="my-4 mx-2">
          <div
            class="font-weight-medium"
            :class="{ 'text-right': windowWidth < 767 }"
          >
            {{ $priceFormatter(toART(sum)) }} {{ $t("global.art") }}
          </div>
          <div class="text-body-1 text-right">{{ $t("components.sum") }}</div>
        </div>
        <div>
          <v-btn v-if="selectedComponent == 'toSend'" :disabled="list.length == 0" text @click="selectAll">
            <span class="mr-2">Select all</span>
            <v-icon v-if="selectedAll == 0">mdi-checkbox-blank-outline</v-icon>
            <v-icon v-if="selectedAll == 1" color="#1976d2"
              >mdi-checkbox-marked</v-icon
            >
            <v-icon v-if="selectedAll == 2" color="#1976d2"
              >mdi-checkbox-intermediate</v-icon
            >
          </v-btn>
        </div>
      </v-card>
      <v-expand-transition>
        <payments
          :data="list"
          :updateSelect="updateSelect"
          class="my-3"
          @updateSelected="updateSelected"
        />
      </v-expand-transition>
      <div class="text-center mt-8">
        <v-btn color="info" @click="loadNext(false)">Load more</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import PageInfo from "../components/pageInfo";
import windowWidth from "@/mixins/windowWidth";
import Switcher from "@/components/switcher.vue";
import Wallet from "@/components/transfer/wallet.vue";
import Payments from "@/components/transfer/payments.vue";
import { priceFormatter } from "@/helpers/priceFormatter";
import config from "/config.js";
const BigNumber = require("bignumber.js");
let timer = setTimeout(() => {}, 1);
export default {
  mixins: [windowWidth],
  components: {
    Payments,
    Wallet,
    Switcher,
    PageInfo,
  },

  data() {
    return {
      selectedComponent: "toSend",
      searchValue: "",
      items: [],
      search: "",
      list: [],
      page: 10,
      last: 0,
      selectedAll: 0,
      selected: [],
      updateSelect: [],
      loading: false,
      sum: 0,
      waitingForBlockchain: false,
      minChars: (value) =>
        value == "" || value.length > 2 || "Min 3 characters",
    };
  },

  watch: {
    "web3.verify": function (val) {
      if (val) {
        this.loadNext();
        this.$store.dispatch("getInfo");
      }
    },
    selectedComponent() {
      this.searchValue = "";
      this.loadNext();
    },

    search(value) {
      clearTimeout(timer);
      if (value == null) return (this.search = "");
      if (value.length > 2) {
        this.loading = true;
        timer = setTimeout(() => {
          this.loadNext();
        }, 1200);
      } else if (value.length == 0) {
        this.loadNext();
      }
    },
    selected(val) {
      let sum = new BigNumber(0);
      val.map((i) => {
        sum = sum.plus(i.amount);
      });
      this.sum = sum;
    },
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    priceFormatter,
    transfer: async function () {
      this.waitingForBlockchain = true;
      let tos = this.selected.map((a) => a.receiver);
      let amounts = this.selected.map((a) => a.amount);
      let ids = this.selected.map((a) => a.id);
      try {
        const receipt = await this.$web3Send(
          "saveBatchTransferToMultipleWallets",
          {},
          this.web3.account,
          tos,
          amounts,
          []
        );
        //console.log(receipt);
        var data = new FormData();
        data.append("jwt", this.web3.jwToken);
        data.append("wallet", this.web3.account);
        data.append("ids", JSON.stringify(ids));
        let response = await this.$request(
          "POST",
          config.apiUrl + "/payout-sent",
          data
        );
        this.loadNext();
        this.waitingForBlockchain = false;
      } catch (e) {
        this.waitingForBlockchain = false;
      }
    },
    hideSelected: async function(){
      let ids = this.selected.map((a) => a.id);
      var data = new FormData();
      data.append("jwt", this.web3.jwToken);
      data.append("wallet", this.web3.account);
      data.append("ids", JSON.stringify(ids));
      data.append("search", this.selectedAll == 1 ? this.search : "");
      let response = await this.$request(
        "POST",
        config.apiUrl + "/payout-hide",
        data
        );
      this.loadNext();
    },
    updateSelected: function (val) {
      this.selected = val;
      if (this.selected.length == 0) {
        this.selectedAll = 0;
      } else {
        this.selectedAll = this.list.length == this.selected.length ? 1 : 2;
      }
    },
    selectAll() {
      let selectedAll = this.selectedAll;
      this.updateSelect = [];
      if (selectedAll != 1) {
        setTimeout(() => {
          this.updateSelect = this.list;
        }, 1);
      }
    },
    toART: function (val) {
      return new BigNumber(val).dividedBy(10 ** 18).toString(10);
    },
    loadNext: async function (clear = true) {
      if(clear){
        this.selected = [];
        this.updateSelect = [];
        this.list = [];
        this.last = 0;
      }
      this.loading = true;
      var data = new FormData();
      data.append("jwt", this.web3.jwToken);
      data.append("wallet", this.web3.account);
      data.append("page", this.page);
      data.append("last", this.last);
      data.append("hide", 0);
      data.append("network", this.web3.network);
      data.append(
        "search",
        this.search.length > 0 ? "%" + this.search + "%" : "%"
      );
      data.append("sent", this.selectedComponent == "sent" ? 1 : 0);
      let response = await this.$request(
        "POST",
        config.apiUrl + "/payout-list",
        data
      );
      if (typeof response.data != "undefined") {
        this.list.push(...response.data);
        this.last += this.page;
      }
      this.loading = false;
    },
  },

  mounted() {
    if (this.web3.verify) {
      this.loadNext();
      this.$store.dispatch("getInfo");
    }
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  .combobox-wrapper {
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 500px;
    }
  }
}
</style>
