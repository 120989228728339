<template>
  <v-expansion-panels class="custom-expansion-panel">
    <v-expansion-panel
      v-for="(item, index) in data"
      :key="index"
      :class="{ 'mt-3': index > 0, 'rounded-lg': true }"
      elevation="1"
    >
      <v-expansion-panel-header hide-actions>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              contain
              :height="windowWidth < 425 ? 40 : 50"
              :width="windowWidth < 425 ? 40 : 50"
              :class="{ hover: attrs['aria-expanded'] == 'true' }"
              class="mx-auto"
              :src="item.network == 0 ? ethereumLogo : polygonLogo"
            />
          </template>
          <span>{{ item.network == 0 ? "Ethereum" : "Polygon" }}</span>
        </v-tooltip>
        <a v-if="windowWidth > 880" :href="etherscanUrl(item.user, 'address20')" target="_blank">
          {{ $shortAddress(item.user, windowWidth - 400) }}
        </a>
        <v-spacer v-if="windowWidth > 880" />
        <div :class="{ 'ml-3': windowWidth < 425 }">
          <div class="text-h7 font-weight-medium">{{ item.player }}</div>
          <div class="text-body-2">
            <span v-if="item.payed">
            {{ $t("components.payed", { dateTime: date(item.payed) }) }}
            </span>
          </div>
        </div>
        <div v-if="windowWidth > 500">
          <div class="font-weight-medium">
            {{ $priceFormatter(item.amountUSD / 100, true) }}
          </div>
          <div class="text-body-2" v-if="item.paid == 1">
            {{ $t("components.paid") }}
          </div>
        </div>
        <div v-if="windowWidth > 625">
          <div class="font-weight-medium">
            {{ $priceFormatter(toETH(item.amount)) }} {{ $t("global.art") }}
          </div>
          <div class="text-body-2">{{ $t("components.belongs") }}</div>
        </div>
        <v-checkbox class="ch-r" v-model="selected" :value="item" />
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="windowWidth < 880">
        <v-divider class="mb-2" />
        <div>
          <div class="font-weight-medium">{{ $t("components.wallet") }}</div>
          <div class="text-body-1">
            {{ $shortAddress(item.user, windowWidth) }}
          </div>
        </div>
        <div v-if="windowWidth < 500" class="mt-2">
          <div class="font-weight-medium">
            {{ $priceFormatter(item.amountUSD / 100, true) }}
          </div>
          <div class="text-body-2" v-if="item.paid == 1">
            {{ $t("components.paid") }}
          </div>
        </div>
        <div v-if="windowWidth < 625" class="mt-2">
          <div class="font-weight-medium">
            {{ $priceFormatter(toETH(item.amount)) }} {{ $t("global.art") }}
          </div>
          <div class="text-body-2">{{ $t("components.belongs") }}</div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ethereumLogo from "./../../assets/ethereum.svg";
import polygonLogo from "./../../assets/polygon.svg";
import windowWidth from "@/mixins/windowWidth";
import moment from "moment";
import { priceFormatter } from "@/helpers/priceFormatter";
const BigNumber = require("bignumber.js");

export default {
  mixins: [windowWidth],
  props: {
    data: {
      type: Array,
      default: () => [
        {
          wallet: "0x765d84D2b650c8acea638B0d5F57E035F738Fb7b",
          player: "Payer NAME",
          createdAt: "2023-01-24 13:54:00",
          fiatAmount: "12450",
          artAmount: "50000000",
        },
      ],
    },
    updateSelect: {
      type: Array,
    },
  },
  data() {
    return {
      panel: [0, 1],
      ethereumLogo: ethereumLogo,
      polygonLogo: polygonLogo,
      selected: [],
    };
  },
  watch: {
    selected: function () {
      this.$emit("updateSelected", this.selected);
    },
    updateSelect: function (val) {
      this.selected = val;
    },
  },

  methods: {
    priceFormatter,
    etherscanUrl: function (hash, type, network) {
      if(typeof this.$etherscanUrl != "undefined")
        return this.$etherscanUrl(hash, type, network);
      else return "";
    },
    date(dateTime) {
      if(dateTime == null) return "";
      return moment(dateTime).format("MM/DD/YYYY h:mm A");
    },
    toETH(val) {
      return new BigNumber(val).dividedBy(10 ** 18).toString(10);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-expansion-panel {
  @media (max-width: 880px) {
    .v-expansion-panel-header {
      cursor: default !important;
    }
  }
  .v-expansion-panel:not(:first-child)::after {
    display: none;
  }

  .v-expansion-panel::before {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(66, 68, 90, 0.23);
    -moz-box-shadow: 0px 0px 15px 0px rgba(66, 68, 90, 0.23);
    box-shadow: 0px 0px 15px 0px rgba(66, 68, 90, 0.23);
  }
}
</style>
